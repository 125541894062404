import Plyr from 'plyr'

// Header nav

const headerElement = document.querySelector('header')
const toggleMenuButton = headerElement.querySelector('.navbar__toggle')
const toggleMenuIcon = toggleMenuButton.querySelector('.material-icons')

toggleMenuButton.addEventListener('click', () => {
  headerElement.classList.toggle('menu-open')
  toggleMenuIcon.textContent = headerElement.classList.contains('menu-open') ? 'close' : 'menu'
})

document.querySelectorAll('main, footer').forEach(el => {
  el.addEventListener('click', () => {
    headerElement.classList.remove('menu-open')
    toggleMenuIcon.textContent = 'menu'
  })
})

// Video player

Array.from(document.querySelectorAll('.plyr')).map((p) => new Plyr(p as HTMLElement))

// Timeout

document.querySelectorAll('section.timeout').forEach(timeoutEl => {
  const date = new Date(timeoutEl.getAttribute('data-time')).getTime()

  const months = timeoutEl.querySelector('.timeout__timer .number.months')
  const days = timeoutEl.querySelector('.timeout__timer .number.days')
  const hours = timeoutEl.querySelector('.timeout__timer .number.hours')
  const minutes = timeoutEl.querySelector('.timeout__timer .number.minutes')

  setInterval(() => {
    const now = new Date().getTime()
    const t = date - now

    months.textContent = ('00' + Math.floor(t / (1000 * 60 * 60 * 24 * 30)).toString()).slice(-2)
    days.textContent = ('00' + Math.floor(t % (1000 * 60 * 60 * 24 * 30) / (1000 * 60 * 60 * 24)).toString()).slice(-2)
    hours.textContent = ('00' + Math.floor(t % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)).toString()).slice(-2)
    minutes.textContent = ('00' + Math.floor(t % (1000 * 60 * 60) / (1000 * 60)).toString()).slice(-2)
  }, 1000)
})

// Funnel

document.querySelectorAll('[data-to]').forEach(btn => {
  btn.addEventListener('click', () => {
    const to = btn.getAttribute('data-to')
    document.querySelectorAll('.phase').forEach(phaseEl => {
      (phaseEl as HTMLElement).style.display = phaseEl.id === to ? 'block' : 'none'
    })
  })
})

document.querySelectorAll('fieldset.code').forEach(codeEl => {
  codeEl.querySelectorAll('input').forEach(digitEl => {
    digitEl.addEventListener('change', () => {
      if (digitEl.nextElementSibling) {
        (digitEl.nextElementSibling as HTMLInputElement)
          .focus()
      } else {
        const to = codeEl.getAttribute('data-to')
        document.querySelectorAll('.phase').forEach(phaseEl => {
          (phaseEl as HTMLElement).style.display = phaseEl.id === to ? 'block' : 'none'
        })
      }
    })
  })
})
